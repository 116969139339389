/* Draco Chan < draco.chan@butterfly.com.au >
 * Main menu and the navigation panel
 * Developed on Butterfly Joomla 1.5 standard install
 * Requirement: jQuery 1.7+
 * Version: 0
 * Usage:
<div id="menubar">
	<div id="navigation"><jdoc:include type="modules" name="navigation" style="none" /></div>
	<div id="navigation-panel">
		<table width="100%" border="0" cellspacing="0" cellpadding="0">
			<tr>
				<td id="navigation-module"><jdoc:include type="modules" name="navigation-module" style="xhtml" /></td>
				<td id="navigation-submenu"><jdoc:include type="modules" name="navigation-panel" style="none" /></td>
			</tr>
		</table>
	</div>
</div>
 * Note: add mainmenu module to both "navigation" and "navigation-panel", set show sub-menu to Yes.
 * add text module to "navigation-module", use menu item class name as module class suffix
 **/

jQuery(document).ready(function() {
	var dropSpeed = 400;

	/*helpers*/
	function clearMenuhover(){
		jQuery('#navigation li.level1').removeClass('hover');
	}

    var navigation_panel = jQuery('#navigation-panel');
    var navigation_overlay = jQuery('#navigation-overlay');

    var openedSearch = false;
    var btnSearch = jQuery('#js-search-btn');
    var searchBar = jQuery('.page-header-top > .header__search');
    var searchBarId = searchBar.attr('id') || 'search-bar-' + (new Date().getTime() + Math.random() + '').replace(/\D/g, '');

    btnSearch.click(function (e) {
        e.preventDefault();
        openedSearch = openedSearch ? closeSearch() : openSearch();
    });

    btnSearch
        .attr({
            'role': 'button',
            'aria-hidden': 'false',
            'aria-haspopup': 'true',
            'aria-expanded': 'false',
            'aria-controls': searchBarId
        });
    searchBar
        .attr({
            'role': 'search',
            id: searchBarId,
            'aria-expanded': 'false',
            'aria-hidden': 'true'
        });

    function openSearch() {
        clearMenuhover();
        jQuery('.page-header').addClass('search-open');
        searchBar.addClass('active')
            .attr({
                'aria-expanded': 'true',
                'aria-hidden': 'false'
            });
        btnSearch.attr('aria-expanded', 'true');

        navigation_overlay.addClass('show');
        return openedSearch = true;
    }

    function closeSearch() {
        jQuery('.page-header').removeClass('search-open');
        searchBar.removeClass('active')
            .attr({
                'aria-expanded': 'false',
                'aria-hidden': 'true'
            });
        navigation_overlay.removeClass('show');
        return openedSearch = false;
    }

    function docClick(e) {
        if (openedSearch && !jQuery.contains(jQuery('.page-header')[0], e.target)) {
            closeSearch();
        }
    }

    /* Sticky Header */
    jQuery(window).scroll(function () {
        if (jQuery(this).scrollTop() > 205 && searchBar.hasClass('active')) {
            closeSearch();
        }
    });

    jQuery(document).on({
        'click': jQuery.proxy(docClick, this)
    });

	/* set min-height to ensure the grid layout */
	jQuery('#navigation-panel, #navigation-panel li.level1').css('display', 'block'); /*need to ensure it's visiable*/
	navigation_panel.find('li.level1').each(function() {
		var height = 0;
        jQuery(this).children('ul').children('li.level2').each(function() {
			var outerHeight = jQuery(this).outerHeight()
            if(outerHeight>height)
				height = outerHeight;
        });
		jQuery(this).find('li.level2').css('min-height', height+'px');
    });
	jQuery('#navigation-panel, #navigation-panel li.level1').css('display', 'none'); /*hide them after min-height is set*/

	/*switch sub menu content and top color*/
	function getJClassname(jElement){ /*replace space with dot for jQuery selector*/
		return '.'+jElement.attr('class').replace(/\s\s+/g, ' ').replace(/\s/g, '.');
	}
	jQuery('#navigation-module .moduletable').each(function() {
		jQuery(this).css('display', 'none');
        var selector = '#navigation li.level1.parent.'+jQuery(this).attr('class').replace('moduletable', '').replace(/\s/g, '');
		jQuery(this).attr('class', 'moduletable '+jQuery(selector).attr('class')); /*add class to the module for switch script*/
    });
	var lastSubmenuClassname = '';
	var lastSubmenuItem = null;
	jQuery('#navigation li.level1').hover(function(){
		var submenuClassname = getJClassname(jQuery(this));
		if(lastSubmenuClassname!=''){
			if(lastSubmenuItem.attr('class')==jQuery(this).attr('class'))
				return true; /*make sure mouse is moved to a new item*/
			jQuery('#navigation-panel '+lastSubmenuClassname).css('display', 'none');
		}
		lastSubmenuClassname = submenuClassname;
		lastSubmenuItem = jQuery(this);
		jQuery('#navigation-panel '+lastSubmenuClassname).css('display', 'block');
		clearMenuhover();
		jQuery(this).addClass('hover');
	}, function(){
		setTimeout(function(){
			if(!navigation_panel.hasClass('hover')){
				clearMenuhover();
			}
		}, 200);
	});

	/*dropdown panel*/
	function initPanel(jElement){
		jElement.hover(function(){
			navigation_panel.addClass('hover');
			if(jElement.is('li')) { /*only open on <li> hover*/
				setTimeout(function(){
					if(!jElement.is(':hover')) {
						return true;
					}
					if(!navigation_panel.hasClass('shown')){ /*show*/
						navigation_panel.addClass('shown');
						navigation_overlay.addClass('show');
						setTimeout(function(){
							navigation_panel.css('height', ''); /*reset it for stop()*/
							navigation_panel.stop(false, false).slideDown(dropSpeed);
							lastSubmenuItem.addClass('hover');
						}, 100); /*delay: let submenu display block*/
					}
					jQuery(this).addClass('hover');
				}, 200); /*delay: hover delay*/
			}
		}, function(){
			navigation_panel.removeClass('hover');
			setTimeout(function(){
				if(!navigation_panel.hasClass('hover') && navigation_panel.hasClass('shown')) { /*hide*/
					navigation_panel.removeClass('shown');
					navigation_overlay.removeClass('show');
					navigation_panel.css('height', '');
					navigation_panel.stop(false, false).slideUp(dropSpeed, function(){
						clearMenuhover();
					});
				}
			}, 200); /*delay: allow user move mouse to the panel*/
		});
	}
	initPanel(navigation_panel);
	jQuery('#navigation li.level1').each(function() {
		if(jQuery(this).hasClass('parent'))
			initPanel(jQuery(this));
	});

	/******Add highlight to open mobile navigation item ******/
	jQuery('.mobile-navigation .accordionmenu .menu li.parent>a .toggle.open').parent().addClass('highlighted');
	jQuery('.mobile-navigation .accordionmenu .menu li.parent>a .toggle.open').addClass('highlighted');

	jQuery(".mobile-navigation .accordionmenu .menu li.parent>a .toggle").click(function(){
		$openmenu=jQuery(this).hasClass('open');
		if($openmenu==true){
			jQuery('.mobile-navigation .accordionmenu .menu li.parent>a .toggle.open').parent().addClass('highlighted');
			jQuery('.mobile-navigation .accordionmenu .menu li.parent>a .toggle.open').addClass('highlighted');
		}else{
			jQuery(this).parent().removeClass('highlighted');
			jQuery(this).removeClass('highlighted');
		}
	});


});
